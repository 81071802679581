<template>
  <div>
    <table>
      <tr>
        <th colspan="11">
          Current timezone: {{ timezone }}<br />
          Total amount: {{ total }}
        </th>
      </tr>
      <tr>
        <th>№</th>
        <th>ID</th>
        <th>event name</th>
        <th>start date</th>
        <th>duration</th>
        <th>created</th>
        <th>updated</th>
        <th>deleted</th>
        <th>amount of users</th>
        <th>users</th>
        <th>actions</th>
      </tr>
      <tr v-for="(timeslot, index) in timeslots" :key="timeslot.id">
        <td>{{ index + 1 }}</td>
        <td>{{ timeslot.id }}</td>
        <td>{{ timeslot.eventName }}</td>
        <td>{{ timeslot.startDate }}</td>
        <td>{{ timeslot.durationInMinutes }}</td>
        <td>{{ timeslot.createdAt }}</td>
        <td>{{ timeslot.updatedAt }}</td>
        <td>{{ timeslot.deletedAt }}</td>
        <td>{{ timeslot?.interviews?.length }}</td>
        <td>
          <template v-if="timeslot?.interviews?.length">
            <div v-for="interview in timeslot?.interviews" :key="interview.id">
              <a :href="getStudentLink(interview.user)">
                {{ interview.user.email }}
              </a>
            </div>
          </template>
        </td>
        <td>
          <ui-button
            v-if="!timeslot.deletedAt"
            class="small-button"
            :wide="true"
            @click="removeTimeslotSoft(timeslot, index)"
            >Remove soft</ui-button
          >
          <ui-button
            v-else
            class="small-button green-background"
            :wide="true"
            @click="recoverTimeslot(timeslot, index)"
            >Recover</ui-button
          >
          <ui-button
            v-if="!timeslot?.interviews?.length"
            :red="true"
            class="small-button"
            :wide="true"
            @click="removeTimeslotHard(timeslot, index)"
            >Remove hard</ui-button
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { paramsForServer } from 'feathers-hooks-common'
import mapFields from '../utils/mapFields'
import UiButton from '../components/ui/UiButton.vue'

export default {
  name: 'timeslots',
  components: { UiButton },
  data() {
    return {
      timeslots: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  },
  computed: {
    ...mapFields({
      userId: 'auth.user.id',
      studentId: 'auth.user.student.id',
      email: 'auth.user.email',
    }),
  },
  async created() {
    try {
      const { total, data } = await this.$store.dispatch(
        'timeslots/find',
        paramsForServer({
          query: {
            startDate: {
              $gt: new Date(),
            },
            $limit: 100,
            $sort: {
              startDate: 1,
            },
          },
          joins: [
            {
              name: 'interviews',
              childs: [{ name: 'user', childs: ['student'] }],
            },
          ],
        })
      )

      this.total = total
      this.timeslots = data
    } catch (error) {
      this.$notify.parseFeathersErrors(error)
    }
  },
  methods: {
    getStudentLink({ student }) {
      return `/applicants?tabName=interview&pageNum=1&pageSize=60&studentId=${student.id}`
    },
    async removeTimeslotHard(timeslot) {
      if (confirm('Are you sure you want to remove it?')) {
        try {
          await this.$store.dispatch('timeslots/remove', [
            timeslot.id,
            paramsForServer({ disableParanoid: true }),
          ])

          this.$notify.success('timeslot removed!')
        } catch (error) {
          this.$notify.parseFeathersErrors(error)
        }
      }
    },
    async removeTimeslotSoft(timeslot) {
      try {
        await this.$store.dispatch('timeslots/remove', [
          timeslot.id,
          paramsForServer({ joins: ['interviews'] }),
        ])

        this.$notify.success('timeslot removed!')
      } catch (error) {
        this.$notify.parseFeathersErrors(error)
      }
    },
    async recoverTimeslot(timeslot) {
      try {
        await this.$store.dispatch('timeslots/patch', [
          timeslot.id,
          { deletedAt: null },
          paramsForServer({
            disableParanoid: true,
            joins: ['interviews'],
          }),
        ])

        this.$notify.success('timeslot recovered!')
      } catch (error) {
        this.$notify.parseFeathersErrors(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  margin: 2rem;
}

th,
td {
  padding: 0.5rem;
  border: 1px solid gray;
}

.small-button {
  font-size: 12px;
  height: 24px;
}

.green-background {
  background: green;
}
</style>
